import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import logo from '../images/logo_covesity.svg'
import logo_comsuk from '../images/logo_comsuk.svg'
import logo_consult from '../images/logo_consult.png'
import { GatsbySeo } from 'gatsby-plugin-next-seo'



export const query = graphql`
  query {
    hero: file(relativePath: { eq: "tower_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({data}) => {

  return (
  <>
    <GatsbySeo title='PanSurg COVesity'
          description='Obesity and COVID-19 related workstreams, resources and research.'/>
      <Hero title="PanSurg COVesity"
            subtitle="Obesity and COVID-19 related workstreams, resources and research."
            fluid={data.hero.childImageSharp.fluid}
            breadcrumbs
        secondcrumb = 'Research'
        secondlink = '/research'
        activecrumb = 'COVesity'
        />

  <section className="section is-small" style={{marginTop:'2rem'}}>
    <div className="container">
      <div className="is-thin">

      <div className="has-text-centered">
        <img src={logo} width="400px" alt="COMS-UK Logo" style={{marginBottom:'2rem'}}/>
      </div>


      <div className="content">

        <p>The obesity pandemic has been a burden globally for decades.
        The current COVID-19 pandemic has superimposed its morbidity, especially
        on those with the disease of obesity and obesity-related comorbidities
        such as type 2 diabetes.</p>
        <p>The COVID and Obesity (COVesity) pandemic will have a considerable impact for some time,
        and the behavioural change around controlling the spread of COVID-19 is already leading to a reduction in activity, poorer food choices and a
        new spike in obesity. These 2 pandemics must be contained together.</p>
        <p>To understand this unique relationship, PanSurg has created obesity-related
        workstreams, resources and research on this important subject.
        The <Link to="/comsuk">COMS-UK</Link> and <Link to="/consult">CONSULT-19 </Link>
        studies will allow data collection on service-provision and training in obesity services and
        <a target="_blank" href="https://app.livestorm.co/pansurg-1/bariatric-surgery-what-next-to-address-a-pandemic-within-a-pandemic"> our webinar </a>
        has already highlighted the important areas of concern.</p>

        <p>Once bariatric surgery recommences in the UK and other places,
        it will be important to collect data on outcomes and morbidities
        related to COIVD-19 and to share best new practices in order to make
        surgery as safe as possible.</p>
      </div>

      <div className="tile is-ancestory">
        <div className="tile is-parent">

          <div className="box tile is-child content has-background-white-ter">
            <div className="has-text-centered">
              <Link to="/comsuk"><img src={logo_comsuk} width="200"/></Link>
            </div>

            <p className="subtitle">Impact of <strong>C</strong>OVID-19 on <strong>O</strong>besity <strong>M</strong>anagement <strong>S</strong>ervices in the <strong>UK</strong></p>

            <div>
              <p>COMS-UK is a cross-sectional survey assessing the impact of COVID-19 on the
              delivery of bariatric services around the UK.</p>

              <p>We want to involve all healthcare professionals caring for bariatric patients in the UK.</p>
              <br />
              <br />
              <br />
              <div className="buttons is-centered">
                <a className="button is-rounded is-red" href="https://www.surveymonkey.co.uk/r/COMS--UK" target="_blank">Complete the survey</a>
              </div>
            </div>
          </div>

        </div>

        <div className="tile is-parent">

          <div className="box tile is-child content has-background-white-ter">
          <div className="has-text-centered">
          <Link to="/consult"><img src={logo_consult} width="200"/></Link>
              </div>
            <p className="subtitle"><strong>C</strong>onsequences<strong> ON</strong><strong> SU</strong>rgica<strong>L T</strong>raining from the COVID-<strong>19</strong> Pandemic</p>

            <div>
              <p>The CONSULT-19 survey has been developed by trainees for trainees, to understand the impact COVID will have on our career progression.</p>
              <p>This short survey allows us to better evaluate the loss of training through redeployment and cancellation of elective lists. Our findings will be shared with all relevant training bodies to help define training in the coming months.</p>
              <div className="buttons is-centered">
                <a className="button is-rounded is-red" href="https://www.surveymonkey.co.uk/r/consult19" target="_blank">Complete the survey</a>
              </div>
            </div>
          </div>

        </div>
      </div>

      </div>
    </div>
  </section>

  <section className="section is-small" style={{marginTop:'2rem'}}>
    <div className="container">
      <div className="is-thin">

      <p><strong>COVesity Team</strong></p>
      <ul style={{textIndent:'1rem'}}>
        <li><a href="mailto:s.purkayastha@imperial.ac.uk"><strong>Lead:</strong> Sanjay Purkayastha</a></li>
        <li>Osama Moussa</li>
        <li>Chanpreet Arhi</li>
        <li>Sami Mansour</li>
        <li>Simon Erridge</li>
        <li>Swathikan Chidambaram</li>
      </ul>



      </div>
    </div>
  </section>





  </>
  )
}
